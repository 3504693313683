/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-03-14",
    versionChannel: "nightly",
    buildDate: "2024-03-14T00:05:42.080Z",
    commitHash: "f472bbc701421c65603f0c2e3457ad6481948d53",
};
